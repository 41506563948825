import React, { useEffect, useState } from 'react'
import logo from "../../assets/logo/dnklogo.svg";
import { GrFacebookOption } from "react-icons/gr";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Ri24HoursLine } from "react-icons/ri";

export const FooterSection = () => {
  const [showButton, setShowButton] = useState();
  const [nav, setNav] = useState(true);
  const navigate = useNavigate();

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300) {
  //     setVisible(true)
  //   } else if (scrolled <= 300) {
  //     setVisible(false)
  //   }
  // }

  // const scrollTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   })
  // }

  // window.addEventListener('scroll', toggleVisible)

  //navigation 

  const goToHome = () => {
    navigate("/");
  };

  const goToAboutHead = () => {
    navigate("/about");
  };

  const goToTeam = () => {
    navigate("/team");
    setNav(!nav);
  };
  const goToContactHead = () => {
    navigate("/contact");
  };

  const goToCareers = () => {
    navigate("/careers");
  };

  const goToServices = () => {
    navigate("/services");
  };

  const goToOffPlan = () => {
    navigate("/off-plan-project");
  };

  const goToBuy = () => {
    navigate("/buy-project");
  };

  const goToSell = () => {
    navigate("/sell-project");
  };

  const today = new Date();
  const year = today.getFullYear();

  useEffect(() => {
    const handleScrollButtonVisiblity = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisiblity);

    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisiblity);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer>
      <div className="w-full bg-[#121218] flex items-center justify-center">
        <div className="footerSection container max-w-[1240px] py-5  px-4  md:py-9">
          <div className="flex items-center justify-between footer-head">
            <img className="w-[200px] py-2" src={logo} alt="logo" />
            <div>
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/_ignitex__?igsh=MWEwbmVmYTE2dGVvaQ%3D%3D"
                    target="_blank"
                  >
                    <GrFacebookOption />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/_ignitex__?igsh=MWEwbmVmYTE2dGVvaQ%3D%3D"
                    target="_blank"
                  >
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/_ignitex__?igsh=MWEwbmVmYTE2dGVvaQ%3D%3D"
                    target="_blank"
                  >
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/_ignitex__?igsh=MWEwbmVmYTE2dGVvaQ%3D%3D"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/+971555769195?text=Hello,"
                    target="_blank"
                  >
                    <IoLogoWhatsapp />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-body grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-7">
            <div className="md:grid-cols-2 lg:col-span-3">
              <ul>
                <li>
                  <h5>Quick Links</h5>
                </li>
                <li>
                  <a className="cursor-pointer" onClick={goToHome}>
                    Home
                  </a>
                </li>
                <li>
                  <a className="cursor-pointer" onClick={goToAboutHead}>
                    About
                  </a>
                </li>
                <li>
                  <a onClick={goToServices} className="cursor-pointer">
                    Services
                  </a>
                </li>
                <li>
                  <a className="cursor-pointer" onClick={goToContactHead}>
                    Contact
                  </a>
                </li>
              </ul>
            </div>

            <div className="w-[100%]">
              <ul className="footer-connects">
                <li>
                  <h5>Connect with us</h5>
                </li>
                <li>
                  <a href="">
                    <IoIosCall className="text-[1.4rem]" />
                    <div>
                      <a href="tel:+971568968905">+971 56 896 8905</a>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@ignitextec.com">
                    <MdEmail className="text-[1.2rem] mr-[3px]" />
                    info@ignitextec.com
                  </a>
                </li>
                <li>
                  <a href="">
                    <MdLocationOn className="text-[1.6rem] mr-[3px]" />
                    <div>
                      <div>Suite No: 2602, Silver Tower</div>
                      <div>Marasi Drive - Business Bay,</div>
                      <div>Dubai, United Arab Emirates.</div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#000000] w-full relative flex items-center justify-center">
        <p className="text-[#ffffff] text-[0.6rem] md:text-[0.7rem]  p-3 m-0 tracking-wider text-center">
          © Copyright {year}. All Rights Reserved | Designed by Ignitex
        </p>
      </div>
      {showButton && (
        <div class="scrollTop-widget">
          <div class="scrollTop bounce-top" onClick={handleScrollTop}>
            <IoIosArrowUp className="arrow-top " />
          </div>
        </div>
      )}
    </footer>
  );
}

export default FooterSection