import logo from './logo.svg';
import './App.scss';
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {Layout} from './component/layout/Layout'
import Home from './component/pages/home/Home';
import About from './component/pages/about/About';
import Team from './component/pages/team/Team';
import ProjectDetail from './component/pages/projectDetail/ProjectDetail';
import Admin from './component/pages/login/Admin';
import DashboardLayout from './component/pages/dashboard/DashboardLayout';
import AddTeam from './component/pages/dashboard/component/AddTeam';
import AddProject from './component/pages/dashboard/component/AddProject';
import ViewList from './component/pages/dashboard/component/ViewList';
import DashboardHome from './component/pages/dashboard/component/DashboardHome';
import Contact from './component/pages/contact/Contact';
import TeamViewList from './component/pages/dashboard/component/TeamViewList';
import TeamDetail from './component/pages/team/TeamDetail';
import BuyProject from './component/pages/projectDetail/BuyProject';
import OurServices from './component/pages/ourService/OurServices';
import CareerPage from './component/pages/career/CareerPage';
import ScrollViewTop from './hooks/ScrollViewTop';
import OffPlanProject from './component/pages/projectDetail/OffPlanProject';
import AddAdImage from './component/pages/dashboard/component/AddAdImage';
import SellProject from './component/pages/projectDetail/SellProject';
import ReviewAdd from './component/pages/dashboard/component/ReviewAdd';
import AddPartner from './component/pages/dashboard/component/AddPartner';
import CampainPage from './component/campainPage/CampainPage';
import { Helmet } from 'react-helmet';
import ServiceSection from './component/pages/home/components/ServiceSection';


function App() {
  const Auth = () => {
    const login = localStorage.getItem('login')
    return login ? <Outlet /> :<Navigate to="/admin"/>
  }
  return (
    <BrowserRouter>
      <Helmet>
        <title>Ignitex Information Technology LLC</title>
        <meta name="keywords" content="Leading IT solutions provider" />
        <meta name="description" content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs." />
        <link rel="icon" href="https://www.ignitextec.com/logo.webp" />
        <link rel="canonical" href="https://www.ignitextec.com" />
        <meta name="author" content="Ignitex" />
        
        <link rel="apple-touch-icon" href="https://www.ignitextec.com/logo.webp" />

        {/* -- Open Graph Meta Tags for WhatsApp and Social Media Sharing -- */}
        <meta property="og:title" content="Ignitex Information Technology LLC" />
        <meta property="og:description" content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs." />
        <meta property="og:image" content="https://www.ignitextec.com/logo.webp" />
        <meta property="og:url" content="https://www.ignitextec.com" />
        <meta property="og:type" content="https://www.ignitextec.com" />

        {/* -- Twitter Card for Sharing -- */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Ignitex Information Technology LLC" />
        <meta name="twitter:description" content="Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs." />
        <meta name="twitter:image" content="https://www.ignitextec.com/logo.webp" />

        {/* Schema Markup for Website */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "@id": "#website",
            "headline": "Ignitex Information Technology LLC",
            "keywords": "New Developments, Off Plan, New Developments in Dubai, Off Plan Projects, Offplan Projects, Off Plan in Dubai, Buy Apartments in Dubai, Buy Villas in Dubai, Buy Townhouses in Dubai, Sale Apartments in Dubai, Sale Villas in Dubai, Sale Townhouses in Dubai, DNK Real Estate, Properties in Dubai, Rent Properties in Dubai, Rent in Dubai, New Off Plan Project, Upcoming Off Plan Properties, New Launch Off-Plan Properties, Dubai Properties Projects, Dubai Real Estate, Real Estate Projects in Dubai, Real Estate Projects in UAE, DNK Real Estate, Real Estate Information, Dubai Developers, Dubai Communities, New Launches, Under Constructions, Ready to Move, Apartment, Villa, Townhouses, Studio, Business Space in Dubai, Office Space in Dubai, Office Space in Business bay Dubai, luxury apartments Dubai, Best Real Estate Company Dubai, Dubai Investment, Dubai Real Estate Market, Downtown Dubai.",
            "image": "https://www.ignitextec.com/logo.webp",
            "description": "Leading IT solutions provider offering managed services, cloud computing, cybersecurity, and IT consulting. Transform your business with innovative technology solutions tailored to your needs.",
            "url": "https://www.ignitextec.com",
            "telephone": "+971 56 896 8905",
            "inLanguage": {
              "@type": "Language",
              "name": ["Arabic", "English","Hindi"]
            },
            "copyrightHolder": {
              "@type": "Organization",
              "name": "Ignitex Information Technology LLC",
              "logo": "https://www.ignitextec.com/logo.webp",
              "url": "https://www.ignitextec.com/",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+971 56 896 8905",
                "contactType": "Sales",
                "email": "info@ignitextec.com",
                "areaServed": "United Arab Emirates"
              },
              "address": {
                "@type": "PostalAddress",
                "addressCountry": "United Arab Emirates",
                "streetAddress": "Silver Tower - Suite No: 2602",
                "addressLocality": "Merasi Drive, Business Bay, Dubai",
                "addressRegion": "Dubai",
                "postalCode": "500001",
                "streetAddress": "Marasi Dive - Business Bay"
              }
            }
          })}
        </script>

        {/* Schema Markup for Organization */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Ignitex Information Technology LLC",
            "logo": "https://www.ignitextec.com/logo.webp",
            "url": "https://www.ignitextec.com/",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+971 55 576 9195",
              "contactType": "Sales",
              "email": "info@ignitextec.com",
              "areaServed": "United Arab Emirates"
            },
            "address": {
              "@type": "PostalAddress",
              "addressCountry": "United Arab Emirates",
              "addressLocality": "Merasi Drive, Business Bay, Dubai",
              "addressRegion": "Dubai",
              "postalCode": "500001",
              "streetAddress": "Marasi Dive - Business Bay"
            }
          })}
        </script>

      </Helmet>

      <ScrollViewTop />
      <Routes>
        <Route path='/admin' element={<Admin />} />
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path='/about' element={<About />} />
          <Route path='/services' element={<OurServices />} />
          <Route path='/contact' element={<Contact />} /> */}
          
        </Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
